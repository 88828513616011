import { css } from 'astroturf';
import React, { Component } from 'react';
import PageHeader from '../components/PageHeader';
import SEO from '../components/SEO';
import Link from '../components/link';
import Layout from '../layout';

const styles = css`
  .resourcelist {
    display: flex;
    justify-content: center;
    align-items: stretch;
    align-content: flex-start;
    flex-wrap: wrap;
    padding-bottom: 10rem;

    & div {
      position: relative;
      text-align: left;
      color: #000;
      margin: 2.25rem 1rem 0;
      background: #fbfbfb;
      border-radius: 0.5rem;
      position: relative;
      box-shadow:
        rgba(0, 0, 0, 0.025) 0 0 0 1px,
        rgba(0, 0, 0, 0.05) 0px 1px 0px 0,
        rgba(0, 0, 0, 0.03) 0px 0px 8px 0,
        rgba(0, 0, 0, 0.1) 0px 20px 30px 0;
      max-width: 24rem;
      padding: 2rem;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-content: stretch;
      align-items: stretch;

      & h3 {
        margin-bottom: 1rem;
      }
    }
  }
`;
class Page extends Component {
  render() {
    return (
      <Layout>
        <SEO
          title="Resources and Tools"
          keywords={['']}
          pathname={this.props.location.pathname}
          description="Polypane is all about improving the workflow for developers and designers. We do that with an excellent developer browser that works on all platforms, but also with free online resources and tools."
        />

        <PageHeader>
          <h1>Resources and Tools</h1>
          <h2>
            Polypane is all about improving the workflow for developers and designers. We do that with an{' '}
            <a href="/product-tour/">excellent developer browser</a> that works on all platforms, but also with{' '}
            <b>free online resources and tools.</b>
          </h2>
        </PageHeader>
        <div className={styles.resourcelist}>
          <div>
            <h3>
              <Link to="/responsive-design-glossary/">Responsive Design Glossary</Link>
            </h3>
            <p>
              Feeling out of your depth when it comes to the terms and jargon used? We wrote a plain-english guide to
              many of the terms you'll come across when learning about responsive design.
            </p>
          </div>
          <div>
            <h3>
              <Link to="/responsive-website-test/">View your screen size wall</Link>
            </h3>
            <p>
              Connect your Google Analytics and this page shows all the screen sizes your actual users have used in the
              past 30 days.
            </p>
          </div>
          <div>
            <h3>
              <Link to="/create-workspace/">Create screen size wall workspace</Link>
            </h3>
            <p>
              Create a Polypane workspace with the most used browser sizes based on your Google Analytics data from the
              past 30 days.
            </p>
          </div>
          <div>
            <h3>
              <Link to="/color-contrast/">Color contrast calculator and suggestions</Link>
            </h3>
            <p>
              Calculate the contrast for any pair of colors to make sure they have enough contrast, and get suggestions
              for better color pairs.
            </p>
          </div>
          <div>
            <h3>
              <Link to="/css-specificity-calculator/">CSS Specificity calculator</Link>
            </h3>
            <p>
              Learning about CSS selector, or wondering why a certain CSS selector is used above another? Use our CSS
              Specificity calculator to quickly find out what's going on.
            </p>
          </div>
          <div>
            <h3>
              <Link to="/blog/the-complete-guide-to-css-media-queries/">The complete guide to CSS Media queries</Link>
            </h3>
            <p>
              Media queries are what make modern responsive design possible. Here's everything you need to know to use
              them effectively in your projects.
            </p>
          </div>

          <div>
            <h3>
              <Link to="/experimental-web-platform-features/">Experimental Chromium Web Platform features</Link>
            </h3>
            <p>
              Polypane ships with experimental web platform features enabled. Find out which ones are available in your
              version of Polypane.
            </p>
          </div>
          <div>
            <h3>
              <Link to="/blog/css-breakpoints-used-by-popular-css-frameworks/">
                CSS breakpoints used by popular CSS frameworks
              </Link>
            </h3>
            <p>Which breakpoints are used by each CSS framework? We listed them all so you can easily compare.</p>
          </div>
          <div>
            <h3>
              <Link to="/css-3d-transform-examples/">Beautiful CSS 3D Transforms</Link>
            </h3>
            <p>
              CSS 3D transforms create depth and visually interesting elements on your page using perspective. Copy over
              the examples and make them your own!
            </p>
          </div>
          <div>
            <h3>
              <Link to="/blog/">Our blog</Link>
            </h3>
            <p>
              We frequently write articles on our blog about Responsive design techniques, methods and tips and other
              deep dives in web technologies.
            </p>
          </div>
        </div>
      </Layout>
    );
  }
}

export default Page;
